import React from "react";
import {Form} from "../../XLibItems";
import {XInputText} from "@michalrakus/x-react-web-lib/XInputText";
import {XFormFooter} from "@michalrakus/x-react-web-lib/XFormFooter";
import {XInputDate} from "@michalrakus/x-react-web-lib/XInputDate";
import {XFormBaseModif} from "@michalrakus/x-react-web-lib/XFormBaseModif";
import {XCheckbox} from "@michalrakus/x-react-web-lib/XCheckbox";
import {OperationType, XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {EnumEnum, Param, Rola, SluzbaEnum} from "../../common/enums";
import {XDropdownEnum} from "../user/XDropdownEnum";
import {Utils} from "../../Utils";
import {XObject} from "@michalrakus/x-react-web-lib/XObject";
import {XAutoComplete} from "@michalrakus/x-react-web-lib/XAutoComplete";
import {Klient} from "../../model/klient/klient.entity";
import {dateAsDB, dateFromModel, stringAsUI, XDateScale} from "@michalrakus/x-react-web-lib/XUtilsConversions";
import {XFormCol} from "@michalrakus/x-react-web-lib/XFormCol";
import {XFormRow} from "@michalrakus/x-react-web-lib/XFormRow";
import {KlientSluzba} from "../../model/klient/klient-sluzba.entity";
import {XMultiSelect} from "@michalrakus/x-react-web-lib/XMultiSelect";
import {XInputTextarea} from "@michalrakus/x-react-web-lib/XInputTextarea";
import {
    XFormAutoCompleteColumn,
    XFormColumn,
    XFormDataTable2,
    XFormTextareaColumn
} from "@michalrakus/x-react-web-lib/XFormDataTable2";
import {MultiSelect} from "primereact/multiselect";
import {XUserDePaul} from "../../model/user/x-user-de-paul.entity";
import {FindUsersRequest} from "../../common/user-api";
import type {XFormProps} from "@michalrakus/x-react-web-lib/XFormBase";
import {XFormHeader} from "@michalrakus/x-react-web-lib/XFormHeader";
import {XUtilsCommon} from "@michalrakus/x-react-web-lib/XUtilsCommon";
import {XFieldChangeEvent} from "@michalrakus/x-react-web-lib/XFieldChangeEvent";
import {XInputDecimal} from "@michalrakus/x-react-web-lib/XInputDecimal";
import {TabPanel, TabView} from "primereact/tabview";
import {XFormScrollable} from "../XFormScrollable";
import {XFormWidth} from "../XFormWidth";
import {xLocaleOption} from "@michalrakus/x-react-web-lib/XLocale";
import {KlientSluzbaKatamneza} from "./KlientSluzbaKatamneza";
import {Sluzba} from "../../model/user/sluzba.entity";
import {KlientSluzbaAdresa} from "./KlientSluzbaAdresa";
import {Tooltip} from "primereact/tooltip";
import {XFormPanelList} from "./XFormPanelList";
import {KlientSluzbaSocRehab} from "../../model/klient/klient-sluzba-soc-rehab.entity";
import {KlientSluzbaCielSpoluprace} from "../../model/klient/klient-sluzba-ciel-spoluprace.entity";
import {XInputTextareaRow} from "./XInputTextareaRow";
import {XInputDateRow} from "./XInputDateRow";
import {KlientSluzbaHodnoteniaDatumy} from "./KlientSluzbaHodnoteniaDatumy";
import {XFormDivider} from "./XFormDivider";
import {KlientSluzbaZakliknuteFieldy} from "./KlientSluzbaZakliknuteFieldy";
import {statSlovensko} from "../../common/constants";
import {XCustomFilter} from "@michalrakus/x-react-web-lib/FindParam";
import {KlientSluzbaSamostCinnost} from "./KlientSluzbaSamostCinnost";
import {XBooleanYesNoBase} from "./XBooleanYesNo";
import {XErrors} from "@michalrakus/x-react-web-lib/XErrors";
import {UtilsCommon} from "../../common/UtilsCommon";
import {XFormBase} from "@michalrakus/x-react-web-lib/XFormBase";
import {XFormInlineRow} from "@michalrakus/x-react-web-lib/XFormInlineRow";

// formular pre dvojicku KlientSluzba/Klient
// KlientSluzba je root zaznam, Klient je prijoinovany zaznam
// formular ma custom save service, ktory sejvuje obidva zaznamy v jednej transakcii (insert/update podla toho ci je id = undefined v prislusnom zazname)

interface OptionGroup {
    label: string;
    items: Option[];
}

interface Option {
    label: string;
    code: string;
}

// ciselnik diagnozy mame (zatial) tuto natvrdo, lebo obsahuje grouping
// (ak bude treba, tak treba vytvorit samostatnu dvojtabulku)
// pozor kody (atribut code) musia byt unique (zapisuju sa do DB)
const diagnozyOptions: OptionGroup[] = [
    {
        label: 's telesným postihnutím',
        items: [
            { label: 'Detská mozgová obrna', code: 'dmo' },
            { label: 'Parkinsonova choroba', code: 'parkinson' },
            { label: 'Skleróza multiplex, muskulárna dystrofia', code: 'sm-md' },
            { label: 'Neurodegeneratívne ochorenia', code: 'neurodegenerativne-ochorenia' },
            { label: 'Iné telesné a neurologické poruchy', code: 'ine-telesne-poruchy' }
        ]
    },
    {
        label: 's duševným postihnutím',
        items: [
            { label: 'Alzheimer a demencie rôzneho typu etyológie', code: 'alzheimer' },
            { label: 'Organický psychosyndróm', code: 'psychosyndrom' },
            { label: 'Pervazívna vývinová porucha', code: 'pervazivna-porucha' },
            { label: 'Schizofrénia a schizotypové poruchy', code: 'schizofrenia' },
            { label: 'Afektívne poruchy', code: 'afektivne-poruchy' },
            { label: 'Iné nešpecifikované duševné poruchy', code: 'ine-dusevne-poruchy' }
        ]
    },
    {
        label: 's mentálnym postihnutím',
        items: [
            { label: 'mentálne postihnutie', code: 'mentalne-postihnutie' }
        ]
    },
    {
        label: 's chronickým ochorením alebo postihnutím',
        items: [
            { label: 'AIDS', code: 'aids' },
            { label: 'Diabetes mellitus', code: 'diabetes' },
            { label: 'Iné chronické ochorenie (napr. kardiovaskulárne ochorenia, astma alebo chronické obštrukčné pľúcne ochorenia)', code: 'ine-chronicke-ochorenie' },
            { label: 'Kombinované', code: 'chronicke-ochorenie-kombinovane' }
        ]
    },
    {
        label: 'so zmyslovým postihnutím',
        items: [
            { label: 'Zrakové', code: 'zrakove-postihnutie' },
            { label: 'Sluchové', code: 'sluchove-postihnutie' },
            { label: 'Kombinované', code: 'zmyslove-postihnutie-kombinovane' }
        ]
    }
];

interface ZakazDatumy {
    datumOd: Date;
    datumDo: Date;
}

// tuto zial neviem povedat triede KlientSluzbaForm, ze je properties su KlientSluzbaFormProps
// ani ChatGPT to nevedel, tak to riesim cast-ovanim this.props a vypinanim TS pri volani komponentu KlientSluzbaForm
export interface KlientSluzbaFormProps extends XFormProps {
    sluzba?: Sluzba;
}

@Form("KlientSluzba")
export class KlientSluzbaForm extends XFormBaseModif {

    // pouzivane pre noclaharen
    private predplateneNoci: number | null = null;
    private zakazyMapFromEditStart: Map<number, ZakazDatumy> | undefined = undefined;

    // casom mozno pojde prec, bude nahradene lazy
    userSocialnyPracovnikList: XUserDePaul[] = [];

    // promise, pomocou ktoreho v onClickSave/onClickCancel cakame na vysledky onChange (onChange vola backend)
    onChangePromise: Promise<boolean> | undefined = undefined;

    constructor(props: KlientSluzbaFormProps) {
        super(props);

        // odkedy renderujeme zvlast formular pre Alzbeta osetrovna, tak joinujeme prakticky vsetko,
        // co neni zrovna idealny stav pre Alzbetu - da sa to o-if-vat podla zvolenej sluzby

        let sluzba: Sluzba | undefined = (this.props as KlientSluzbaFormProps).sluzba;
        if (!sluzba) {
            sluzba = Utils.getCurrentSluzba();
        }
        if (sluzba?.kod === SluzbaEnum.noclaharen || sluzba?.kod === SluzbaEnum.alzbetaOsetrovna) {
            // maly formular bez tab-iek pouzivany v noclaharni a osetrovni
            // optimalizacia - nacitame len to co potrebujeme v noclaharni a Alzbete

            // chceme vediet aky formular vykreslit
            this.addField("sluzba.kod");

            // tab-ka 1 - zdielane udaje / formular Alzbeta osetrovna
            this.addField("klient.pohlavie.name");
            this.addField("klient.obec.okres.nazov"); // chceme vidiet aj okres v autocomplete
            this.addField("klient.modifXUser.name");
            this.addField("klientSluzbaZakazList.id");

            // najoinujeme zaznamy KlientSluzba na zazname klient, aby sme vedeli zobrazit zoznam sluzieb do ktorych klient patri
            this.addField("klient.klientSluzbaList.sluzba.nazov");
        }
        else {
            // hlavny velky formular s tab-kami

            // chceme vediet aky formular vykreslit
            this.addField("sluzba.kod");

            // tab-ka 1 - zdielane udaje / formular Alzbeta osetrovna
            this.addField("klient.pohlavie.name");
            this.addField("klient.obec.okres.nazov"); // chceme vidiet aj okres v autocomplete
            this.addField("klient.modifXUser.name");
            this.addField("klientSluzbaZakazList.id");

            // najoinujeme zaznamy KlientSluzba na zazname klient, aby sme vedeli zobrazit zoznam sluzieb do ktorych klient patri
            this.addField("klient.klientSluzbaList.sluzba.nazov");

            // joiny potrebne v druhom, tretom, ... tab-e
            // poznamka: v TabView nepouzivame renderActiveOnly={false}, lebo potom nam vykresli XInputTextarea velmi uzke (pravdepodobne bug v primereact)
            // zaroven vsak neskorsie renderovanie tab-iek sposobuje ze nemame vsetky join-y ktore nam treba, preto tie join-y musime pridat manualne

            // tab-ka mapovanie
            this.addField("modifXUser.name");
            this.addField("klucovyPracovnikXUser.name");
            if (Utils.isSluzbaStreetwork() || Utils.isSluzbaAlzbetaPoradenstvo()) {
                //this.addField("miestoMoznehoKontaktuKlientSluzbaList.miestoMoznehoKontaktu.miesto");
                this.addField("miestoZdrziavaniaMestskaCast.name");
            }

            // tab-ka socialna anamneza
            this.addField("socPostavenieList.name");
            this.addField("rodinnyStav.name");
            this.addField("uzivatelSluzbyList.name");
            this.addField("dokladList.name");
            this.addField("klientSluzbaPrijemList.druhPrijmu.name");
            this.addField("vzdelanie.name");

            // tab-ka zdravotna anamneza
            this.addField("klientSluzbaOdkazanostList.odkazanost.name");
            this.addField("klientSluzbaOdkazanostList.stupenOdkazanosti.name");

            // tab-ka ukoncenie
            this.addField("klientSluzbaUkonceniePoskytSocPoradList.poslKlucovyPracovnikXUser.name");

            // tab-ka individualny plan
            this.addField("klientSluzbaCielSpolupraceList.id");

            // tab-ka rizikovy plan
            this.addField("podielajucaSaOsobaList.name");

            // tab-ka socialna rehabilitacia
            this.addField("klientSluzbaSocRehabList.id");

            // tab-ka samostatnost
            this.addField("samostJedeniePitie.name");
            this.addField("samostObliekanieVyzliekanie.name");
            this.addField("samostKupanieOsobnaHygiena.name");
            this.addField("samostPouzitieWC.name");
            this.addField("samostPresunZoStolickyDoPostele.name");
            this.addField("samostChodzaPoRovine.name");
            this.addField("samostChodzaPoSchodoch.name");
        }

        this.onChangeKlientMenoPriezviskoDatumNarodenia = this.onChangeKlientMenoPriezviskoDatumNarodenia.bind(this);
        this.onClickAddRowUkoncenie = this.onClickAddRowUkoncenie.bind(this);
        this.panelBodyKlientSluzbaSocRehab = this.panelBodyKlientSluzbaSocRehab.bind(this);
        this.panelBodyKlientSluzbaCielSpoluprace = this.panelBodyKlientSluzbaCielSpoluprace.bind(this);
    }

    async componentDidMount() {
        if (Utils.isSluzbaNoclaharen()) {
            // niekedy potrebujeme v preInitForm, preto si dopredu nacitame
            // TODO - ak by sme vedeli parametre inac predcitat, tak si mozme tento request usetrit
            this.predplateneNoci = await Utils.getXParamValueAsInt(Param.n_novyKlientPredplateneNoci);
        }

        await super.componentDidMount(); // tu sa vola createNewObject a preInitForm

        if (Utils.isSluzbaSocPorad()) {
            const sluzbaKod: SluzbaEnum = Utils.getCurrentSluzba()!.kod as SluzbaEnum;
            // nacitame si zoznam userov ktori mozu robit spec/psych poradenstvo (tych budeme ponukat v prislusnych autocomplete-och)
            this.userSocialnyPracovnikList = await XUtils.fetchMany('find-users', {sluzbaKod: sluzbaKod, rolaCode: Rola.socialnyPracovnik} satisfies FindUsersRequest);

            this.setStateXForm(); // aby sa prejavili zmeny ktore zavisia od nacitanych zoznamov
        }
    }

    createNewObject(): XObject {
        // inicializujeme not null boolean-y a tie list-y, ktore su zobrazene vo formulari - tie vecsinou sedia s tymi co maju zapnuty cascade (su agregovane)
        return {
            klient: {
                datumNarodeniaIbaRok: Utils.getCurrentSluzba()?.kod === SluzbaEnum.alzbetaOsetrovna,
                datumNarodeniaOverenyUdaj: false,
                rodneCisloOverenyUdaj: false,
                stat: statSlovensko,
                obecText: null,
                obec: null,
                klientSluzbaList: [],
                version: 0
            },
            sluzba: Utils.getCurrentSluzba(),
            predplateneNoci: this.predplateneNoci,
            uzivatel: false,
            klientSluzbaZakazList: [],
            klientSluzbaPrijemList: [],
            klientSluzbaOdkazanostList: [],
            klientSluzbaCielSpolupraceList: [],
            rizikaRozumovaZlozka: null,
            rizikaVolovaZlozka: null,
            klientSluzbaSocRehabList: [],
            miestoMoznehoKontaktuKlientSluzbaList: [],
            klientSluzbaUkonceniePoskytSocPoradList: [],
            version: 0
        };
    }

    preInitForm(klientSluzba: KlientSluzba, operationType: OperationType.Insert | OperationType.Update) {
        // ulozime si hodnoty datumov zo zakazov zo zaciatku editacie
        this.zakazyMapFromEditStart = new Map<number, ZakazDatumy>();
        for (const klientSluzbaZakaz of klientSluzba.klientSluzbaZakazList) {
            this.zakazyMapFromEditStart.set(klientSluzbaZakaz.id, {datumOd: klientSluzbaZakaz.datumOd, datumDo: klientSluzbaZakaz.datumDo});
        }

        // ak otvarame klienta vyhladaneho cez HladatKlientaMimoSluzbuDialog tak je to klient z cudzej sluzby a treba mu nastavit predplateneNoci
        if (klientSluzba.id === undefined && klientSluzba.sluzba.kod === SluzbaEnum.noclaharen) {
            klientSluzba.predplateneNoci = this.predplateneNoci;
        }
    }

    // pomocna metodka
    private getKlientSluzba(): KlientSluzba | null {
        return this.state.object;
    }

    async onChangeKlientMenoPriezviskoDatumNarodenia(e: XFieldChangeEvent<KlientSluzba>) {
        if (this.isAddRow()) {
            if (e.object.klient.meno && e.object.klient.priezvisko && e.object.klient.datumNarodenia) {

                let zastavitSave: boolean = false; // ak sa najde existujuci klient (a otvori sa message box s otazkou), tak zastavime save (nezatvorime formular),
                                                    // ak user po typovani mena klikol priamo na Save button (vtedy idu po sebe 2 eventy - onChange a onClick)
                let resolveOnChangePromise: ((value: boolean | PromiseLike<boolean>) => void) | undefined = undefined;
                this.onChangePromise = new Promise((resolve) => {
                    // standardne by sme mali zavolat "resolve(result)" po ukonceni tejto Promise metody
                    // (metoda resolve meni stav z pending na resolved, nasledkom coho sa zavola kod za "await this.onChangePromise")
                    // tu vsak vyuzijeme drobny hack - ulozime si resolve do lokalnej premennej a zavolame ho az na konci tejto onChange metody (ked uz vieme ci sme zobrazili message box alebo nie)
                    resolveOnChangePromise = resolve;  // Expose this for resolving later
                });

                const datumNarodeniaAsDB: string = dateAsDB(dateFromModel(e.object.klient.datumNarodenia));
                let filterDatum: XCustomFilter;
                if (e.object.klient.datumNarodeniaIbaRok) {
                    // mame len rok, musi sediet rok
                    filterDatum = {where: `date_trunc('year', [klient.datumNarodenia])::DATE = date_trunc('year', ${datumNarodeniaAsDB})::DATE`, params: {}};
                }
                else {
                    // mame cely datum narodenia - ak je v zazname uvedeny rok, tak musi sediet rok, ak je v zazname cely datum, tak musi sediet cely datum)
                    filterDatum = {where: `(([klient.datumNarodeniaIbaRok] AND date_trunc('year', [klient.datumNarodenia])::DATE = date_trunc('year', ${datumNarodeniaAsDB}))` +
                            ` OR ((NOT [klient.datumNarodeniaIbaRok]) AND [klient.datumNarodenia] = ${datumNarodeniaAsDB}))`, params: {}};
                }
                const filter: XCustomFilter | undefined = XUtilsCommon.filterAnd(
                    {where: `${Utils.getSchema()}.unaccent([klient.meno]) ILIKE ${Utils.getSchema()}.unaccent(:meno)` +
                            ` AND ${Utils.getSchema()}.unaccent([klient.priezvisko]) ILIKE ${Utils.getSchema()}.unaccent(:priezvisko)`, params: {meno: e.object.klient.meno, priezvisko: e.object.klient.priezvisko}},
                    filterDatum
                );
                // zosortujeme podla "id desc" - najnovsie zaznamy ako prve
                const klientSluzbaList: KlientSluzba[] = await XUtils.fetchRows('KlientSluzba', filter, "id desc",
                    ["sluzba.kod", "klient.pohlavie.name", "klient.obec.okres.nazov", "klient.modifXUser.name", "klientSluzbaZakazList.id", "klient.klientSluzbaList.sluzba.nazov"]);
                if (klientSluzbaList.length > 0) {
                    // najprv skusime najst zaznam z aktualnej sluzby
                    const klientSluzbaAktualnaList: KlientSluzba[] = klientSluzbaList.filter((value: KlientSluzba) => value.sluzba.id === Utils.getCurrentSluzba()?.id);
                    if (klientSluzbaAktualnaList.length > 0) {
                        const klientSluzba: KlientSluzba = klientSluzbaAktualnaList[0];
                        zastavitSave = true; // kedze otvarame message box, tak zastavime save (ak user odisiel z inputu priamo na Save button)
                        if (window.confirm(`Našiel sa záznam pre klienta "${Utils.klientCreateIDInfo(klientSluzba.klient)}" pre aktuálnu službu ${Utils.getCurrentSluzba()?.nazov}.${XUtilsCommon.newLine}`
                            + `${klientSluzbaAktualnaList.length > 1 ? `Počet nájdených záznamov: ${klientSluzbaAktualnaList.length}${XUtilsCommon.newLine}` : ``}`
                            + XUtilsCommon.newLine
                            + `OK - použiť existujúci nájdený záznam (zadané údaje budú prepísané)${XUtilsCommon.newLine}`
                            + `Cancel - pokračovať vo vytváraní nového záznamu`)) {
                            // prejdeme do (plneho) update rezimu
                            this.ponechajDatumNarodeniaAkPrisielRok(klientSluzba.klient, e.object.klient.datumNarodenia, e.object.klient.datumNarodeniaIbaRok);
                            this.setState({object: klientSluzba});
                        }
                    }
                    else {
                        // mame len zaznamy z cudzich sluzieb - pouzijeme len cast Klient z prveho zaznamu
                        const klient: Klient = klientSluzbaList[0].klient;
                        // zratame vsetkych najdenych (kozmeticka zalezitost)
                        const klientIdSet: Set<number> = new Set<number>();
                        for (const klientSluzba of klientSluzbaList) {
                            klientIdSet.add(klientSluzba.klient.id);
                        }
                        zastavitSave = true;
                        if (window.confirm(`Našiel sa záznam pre klienta "${Utils.klientCreateIDInfo(klient)}" (služby: ${klient.klientSluzbaList.map((value: KlientSluzba) => value.sluzba.nazov).join(", ")}).${XUtilsCommon.newLine}`
                            + `${klientIdSet.size > 1 ? `Počet nájdených záznamov: ${klientIdSet.size}${XUtilsCommon.newLine}` : ``}`
                            + XUtilsCommon.newLine
                            + `OK - použiť existujúci nájdený záznam (zadané zdieľané údaje budú prepísané)${XUtilsCommon.newLine}`
                            + `Cancel - pokračovať vo vytváraní nového záznamu`)) {
                            // zapiseme zaznam Klient (KlientSluzba s id = undefined zostava)
                            const klientSluzba: KlientSluzba = this.getKlientSluzba()!;
                            this.ponechajDatumNarodeniaAkPrisielRok(klient, e.object.klient.datumNarodenia, e.object.klient.datumNarodeniaIbaRok);
                            klientSluzba.klient = klient;
                            this.setState({object: klientSluzba});
                        }
                    }
                }

                if (resolveOnChangePromise) {
                    // ukoncime this.onChangePromise zavolanim resolve metody (kedze sme volali await tak resolveOnChangePromise by mal byt vzdy nastaveny (system spustil (asynchronnu) metodu objektu Promise)
                    // netusim, preco tu musim explicitne castovat len preto ze som pridal if
                    (resolveOnChangePromise as ((value: boolean | PromiseLike<boolean>) => void))(zastavitSave);
                }
                else {
                    if (zastavitSave) {
                        // toto by sa nemalo stat, ak ano tak nam tento mechanizmus na zastavenie save nezafunguje (a user vytvori duplicitneho klienta)
                        console.log("Neocakavana chyba - resolveOnChangePromise je undefined a zastavitSave je true");
                    }
                }
                this.onChangePromise = undefined; // vratime do povodneho stavu (dolezite je to pre usecase ak resolveOnChangePromise = undefined, vtedy by sa onClickSave nedockal ukoncenia onChangePromise - ten usecase by aj tak nemal nastat...)
            } // if (e.object.klient.meno && e.object.klient.priezvisko && e.object.klient.datumNarodenia)
        }
    }

    // pomocna metodka - zapise datumNarodenia do "najdenyKlient" ak je v klientovi len rok (rok narodenia nam neprepise datum narodenia)
    ponechajDatumNarodeniaAkPrisielRok(najdenyKlient: Klient, datumNarodenia: Date, datumNarodeniaIbaRok: boolean) {
        if (najdenyKlient.datumNarodeniaIbaRok && !datumNarodeniaIbaRok) {
            najdenyKlient.datumNarodenia = datumNarodenia;
            najdenyKlient.datumNarodeniaIbaRok = false;
        }
    }

    onChangeKlientDatumNarodeniaIbaRok(e: XFieldChangeEvent<KlientSluzba>) {
        if (e.object.klient.datumNarodeniaIbaRok) {
            const datumNarodenia: Date | null = dateFromModel(e.object.klient.datumNarodenia);
            if (datumNarodenia && (datumNarodenia.getMonth() !== 0 || datumNarodenia.getDay() !== 1)) {
                e.object.klient.datumNarodenia = new Date(`${datumNarodenia.getFullYear()}-01-01`);
            }
        }
    }

    onChangeUzivatel(e: XFieldChangeEvent<KlientSluzba>) {
        if (e.object.uzivatel) {
            e.object.testovany = Utils.todayNoclaharen();
        }
        else {
            e.object.testovany = null;
        }
    }

    onClickAddRowUkoncenie() {
        this.onTableAddRow("klientSluzbaUkonceniePoskytSocPoradList", {
            datum: XUtilsCommon.today(),
            dovod: null,
            datumPoslKontaktu: XUtilsCommon.today(),
            poslKlucovyPracovnikXUser: this.getKlientSluzba()!.klucovyPracovnikXUser
        });
    };

    async onClickSave() {
        // ak zobrazime message box ohladne existujuceho klienta, tak nechceme vykonat save a nechceme ani zatvorit formular
        // kedze onChangeKlientMenoPriezviskoDatumNarodenia ma v sebe await a teda skonci neskor ako tento onClickSave,
        // tak pomocou this.onChangePromise pockame kym dobehne onChangeKlientMenoPriezviskoDatumNarodenia
        if (this.onChangePromise) {
            const zastavitSave: boolean = await this.onChangePromise;
            if (zastavitSave) {
                return;
            }
        }
        super.onClickSave();
    }

    async onClickCancel() {
        // podobne ako v onClickSave nezatvorime formular ak nam onChange zobrazi message box
        if (this.onChangePromise) {
            const zastavitSave: boolean = await this.onChangePromise;
            if (zastavitSave) {
                return;
            }
        }
        super.onClickCancel();
    }

    async validate(klientSluzba: KlientSluzba): Promise<XErrors> {
        const errors: XErrors = {};

        if (this.mestskaCastPovinna(klientSluzba.sluzba)) {
            if (!klientSluzba.miestoZdrziavaniaMestskaCast) {
                errors.miestoZdrziavaniaMestskaCast = "Miesto zdržiavania - mestská časť musí byť vyplnená.";
            }
        }

        // noclaharen - viac ako 14 dnovy zakaz moze udelit len Veduci
        if (Utils.isSluzbaNoclaharen()) {
            for (const klientSluzbaZakaz of klientSluzba.klientSluzbaZakazList) {
                const datumOd: Date | null = dateFromModel(klientSluzbaZakaz.datumOd);
                const datumDo: Date | null = dateFromModel(klientSluzbaZakaz.datumDo);
                if (datumOd !== null && datumDo !== null) {
                    const zakazPocetDni: number = UtilsCommon.dateDiff(datumOd, datumDo)! + 1;
                    if (zakazPocetDni > 14) {
                        // skontrolujeme ci user zmenil niektory z datumov
                        const zakazDatumy: ZakazDatumy | undefined = this.zakazyMapFromEditStart!.get(klientSluzbaZakaz.id);
                        if (zakazDatumy === undefined || !XUtilsCommon.dateEquals(datumOd, dateFromModel(zakazDatumy.datumOd)) || !XUtilsCommon.dateEquals(datumDo, dateFromModel(zakazDatumy.datumDo))) {
                            // mame novy zaznam alebo sa zmenil jeden z datumov
                            if (!Utils.userMaSluzbuRolu(SluzbaEnum.noclaharen, Rola.veduci)) {
                                //zapiseme chyby do zaznamu zapis do specialneho technickeho atributu
                                XFormBase.saveErrorsIntoXRowTechData(klientSluzbaZakaz, {datumDo: "Viac ako 14 dňové prerušenie môže zapísať len používateľ v roli Vedúci"});
                            }
                        }
                    }
                }
            }
        }

        return errors;
    }

    mestskaCastPovinna(sluzba: Sluzba): boolean {
        return sluzba?.kod === SluzbaEnum.streetwork;
    }

    preSave(object: XObject) {
        super.preSave(object); // <- tu sa zapisuju object.modifDate a object.modifXUser
        object.klient.modifDate = object.modifDate;
        object.klient.modifXUser = object.modifXUser;
    }

    // overridneme standardny saveRow
    async saveRow(): Promise<KlientSluzba> {
        // kontrola - toto by nam sposobilo problemy pri zapise
        if (!this.state.object.klient) {
            throw "Unexpected error: klient musi byt vyplneny";
        }
        // zrusili sme cascade save na asociacii klient.klientSluzbaList
        // if (this.state.object.klient.klientSluzbaList !== undefined) {
        //     throw "Unexpected error: klient.klientSluzbaList musi byt undefined (na klientovi sa nesmie citat/nastavovat oneToMany asociacia klientSluzbaList)";
        // }
        const reload: boolean = this.props.onSaveOrCancel !== undefined;
        const klientSluzba: KlientSluzba = await XUtils.fetch('save-klient-sluzba', {entity: this.getEntity(), object: this.state.object, reload: reload});
        if (reload) {
            // ak robime reload (napr. editujeme objekt cez XAutoComplete), tak nam vracia zlu hodnotu derivovaneho atributu menoPriezviskoPrezyvka
            // je to len taka kozmeticka vec, aby sme videli v XAutoComplete spravnu hodnotu
            // zodpoveda funkcii v databaze
            const klient: Klient = klientSluzba.klient;
            klient.menoPriezviskoPrezyvka = `${stringAsUI(klient.meno)} ${stringAsUI(klient.priezvisko)} ${stringAsUI(klient.prezyvka)}`.trim();
        }
        return klientSluzba;
    }

    createLabel(): string {
        let label: string = "Klient";
        const klient: Klient | undefined = this.getKlientSluzba()?.klient;
        if (klient) {
            label = Utils.klientCreateLabel(label, klient);
            if (klient.id === undefined) {
                label += " - " + xLocaleOption('newRow');
            }
        }
        // toto je kozmeticke - vypiseme sluzbu ak sme formular otvorili z formulara KlientForm
        const sluzbaFromProps: Sluzba | undefined = (this.props as KlientSluzbaFormProps).sluzba;
        if (sluzbaFromProps) {
            label += " - " + sluzbaFromProps.nazov;
        }

        return label;
    }

    createChipsSluzby(): JSX.Element[] {
        let sluzbaList: string[] = [];
        const klientSluzba: KlientSluzba | null = this.getKlientSluzba();
        if (klientSluzba) {
            for (const klientSluzbaZKlienta of klientSluzba.klient.klientSluzbaList) {
                sluzbaList.push(klientSluzbaZKlienta.sluzba.nazov);
            }
        }
        return Utils.createChips(sluzbaList);
    }

    panelBodyKlientSluzbaCielSpoluprace(entity: string, row: KlientSluzbaCielSpoluprace, removeButton: JSX.Element): JSX.Element {
        return (
            <XFormRow>
                <XFormCol width="full">
                    <div className="x-form-inline-row justify-content-between">
                        {/* poznamka: 3rem je sirka pre remove button */}
                        <div className="x-form-inline-row" style={{width: 'calc(100% - 3rem)'}}>
                            <XInputDateRow form={this} entity={entity} rowData={row} field="datumOd"
                                           label="Dátum začiatok" labelStyle={{width:'8rem'}}/>
                            {/* poznamka: 36rem je sirka ostatnych fieldov/labelov v riadku - zistene experimentalne */}
                            <XInputTextareaRow form={this} entity={entity} rowData={row} field="ciel" rows={1} fieldStyle={{width: 'calc(100% - 33rem)'}}
                                               label="Cieľ" labelStyle={{width:'3rem'}} desc="Cieľ je dohoda s klientom k naplneniu jeho potreby. (čo má byť záverom intervencie, čo chceme dosiahnuť...)."/>
                            <XInputDateRow form={this} entity={entity} rowData={row} field="datumDo"
                                           label="Dátum koniec" labelStyle={{width:'8rem'}} desc="Časové vymedzenie naplnenia cieľa - do kedy ho chceme naplniť. Aj keď sa cieľ nenaplnil, treba uviesť dátum ukončenia."/>
                        </div>
                        <div className="field grid">
                            {removeButton}
                        </div>
                    </div>
                    <XInputTextareaRow form={this} entity={entity} rowData={row} field="ciastkoveCiele"
                                       label="Čiastkové ciele" labelStyle={{width:'5.5rem'}} desc="Čiastkové ciele - kroky ako naplniť cieľ, kto to urobí, čo sa podarilo pri naplnení cieľa vybaviť (napr. dokumenty, lekárov, atď.), je klient schopný vykonať čiastkový cieľ sám, s pomocou?
Záver či je cieľ splnený / nesplnený / zmena cieľa a prečo."						/>
                </XFormCol>
            </XFormRow>
        );
    }

    panelBodyKlientSluzbaSocRehab(entity: string, row: KlientSluzbaSocRehab, removeButton: JSX.Element): JSX.Element {
        return (
            <XFormRow>
                <XFormCol width="full">
                    <div className="x-form-inline-row justify-content-between">
                        <div className="x-form-inline-row">
                            <XInputDateRow form={this} entity={entity} rowData={row} field="datumSpracovania"
                                           label="Dátum spracovania"/>
                            <XInputDateRow form={this} entity={entity} rowData={row} field="datumVyhodnotenia"
                                           label="Dátum vyhodnotenia" labelStyle={{width:'11rem'}}/>
                        </div>
                        <div className="field grid">
                            {removeButton}
                        </div>
                    </div>
                    <XInputTextareaRow form={this} entity={entity} rowData={row} field="ciele" rows={1}
                                       label="Ciele" labelStyle={{width:'5.5rem'}} desc="Čo chce / potrebuje zlepšiť"/>
                    <XInputTextareaRow form={this} entity={entity} rowData={row} field="plan" rows={1}
                                       label="Plán" labelStyle={{width:'5.5rem'}} desc="Ako chce dosiahnuť cieľ, čo bude robiť sám / skupinovo, ako často, dohodnutý časový horizont prehodnotenia"/>
                    <XInputTextareaRow form={this} entity={entity} rowData={row} field="vysledok"
                                       label="Výsledok" labelStyle={{width:'5.5rem'}}/>
                </XFormCol>
            </XFormRow>
        );
    }

    // korektne riesenie, ale vadilo mi preblikavanie cierneho formulara,
    // tak radsej hned vykreslujem formular, ktory sa neskor zaplni hodnotami
    // (mozno je to aj rychlejsie ako vykreslovat formular az po nacitani dat, netusim)
    /*
    renderOld() {
        const klientSluzba: KlientSluzba | null = this.getKlientSluzba();
        if (!klientSluzba) {
            // nemame este nacitany/vytvoreny objekt (nezbehol componentDidMount)
            return <div>Formulár sa načítava...</div>;
        }
        else if (klientSluzba.sluzba.kod === SluzbaEnum.alzbetaOsetrovna) {
            // maly formular bez tab-iek pouzivany v osetrovni, podobny skor formularu klienta noclaharne
            return this.renderAlzbetaOsetrovna();
        }
        else {
            // hlavny velky formular s tab-kami
            return this.renderSocPorad();
        }
    }
    */

    render() {
        // sluzbu berieme z troch zdrojov - prop, DB zaznam KlientSluzba (nacitany podla id) a zvolena sluzba z Dropdown-u
        // prop a DB zaznam su vzdy spravne, ale prop sa pouziva len pri otvarani z KlientForm a DB zaznam je k dispozicii az po nacitani z DB (ak by sme pouzili len tento sposob, tak by formular preblikaval)
        // preto pouzivame ako zdroj aj Dropdown
        let sluzba: Sluzba | undefined = (this.props as KlientSluzbaFormProps).sluzba;
        if (!sluzba) {
            sluzba = this.getKlientSluzba()?.sluzba;
        }
        if (!sluzba) {
            sluzba = Utils.getCurrentSluzba();
        }
        if (!sluzba) {
            // nemalo by sa stat
            return <div>Vyberte službu.</div>;
        }
        else if (sluzba.kod === SluzbaEnum.noclaharen) {
            // formular klienta noclaharne
            return this.renderNoclaharen();
        }
        else if (sluzba.kod === SluzbaEnum.alzbetaOsetrovna) {
            // maly formular bez tab-iek pouzivany v osetrovni, podobny skor formularu klienta noclaharne
            return this.renderAlzbetaOsetrovna();
        }
        else {
            // hlavny velky formular s tab-kami
            return this.renderSocPorad(sluzba);
        }
    }

    renderNoclaharen() {
        return (
            <div>
                <XFormHeader form={this} label={this.createLabel()} appendNewRow={false}/>
                <XFormScrollable form={this} widthFitContent={true}>
                    <XFormRow>{/* tato najvrchnejsia uroven XFormRow/XFormCol je tu na to aby ohranicila XInputTextarea "poznamka", inac by bol roztiahnuty na cely formular */}
                        <XFormCol>
                            <XFormRow>
                                <XFormCol labelStyle={{width:'10rem'}}>
                                    <XInputText form={this} field="klient.meno" label="Meno" inputStyle={{width:'15rem'}} readOnly={false} onChange={this.onChangeKlientMenoPriezviskoDatumNarodenia}/>
                                    <XInputText form={this} field="klient.priezvisko" label="Priezvisko" inputStyle={{width:'15rem'}} readOnly={false} onChange={this.onChangeKlientMenoPriezviskoDatumNarodenia}/>
                                    <XInputText form={this} field="klient.prezyvka" label="Prezývka" inputStyle={{width:'15rem'}} readOnly={false}/>
                                    <XInputText form={this} field="klient.rodneCislo" label="Rodné číslo" inputStyle={{width:'7rem'}} readOnly={false} placeholder="000000/0000"/>
                                    <div className="x-form-inline-row">
                                        <XInputDate form={this} field="klient.datumNarodenia" label="Dátum narod." labelStyle={{width:'10rem'}} readOnly={false} onChange={this.onChangeKlientMenoPriezviskoDatumNarodenia}
                                                    scale={this.getKlientSluzba()?.klient?.datumNarodeniaIbaRok ? XDateScale.Year : XDateScale.Date}/>
                                        <XCheckbox form={this} field="klient.datumNarodeniaIbaRok" label="iba rok" labelStyle={{width:'5rem'}} readOnly={false} onChange={this.onChangeKlientDatumNarodeniaIbaRok}/>
                                    </div>
                                    <XDropdownEnum form={this} assocField="klient.pohlavie" label="Pohlavie" enumEnumCode={EnumEnum.pohlavie} readOnly={false}/>
                                </XFormCol>
                                <XFormCol>
                                    <KlientSluzbaAdresa form={this} osetrovna={false} labelStyle={{width:'9rem'}}/>
                                </XFormCol>
                                <XFormCol labelStyle={{width:'11rem'}}>
                                    <XInputText form={this} field="cisloVreca" label="Číslo vreca" readOnly={true} inputStyle={{width:'5rem'}}/>
                                    <XInputText form={this} field="predplateneNoci" label="Predplatené noci" inputStyle={{width:'5rem'}} readOnly={!Utils.userMaSluzbuRolu(SluzbaEnum.noclaharen, Rola.veduci, Rola.socialnyPracovnik)}/>
                                    <XFormInlineRow>
                                        <XCheckbox form={this} field="uzivatel" label="Užívateľ" labelStyle={{width:'11rem'}} onChange={this.onChangeUzivatel}/>
                                        <XInputDate form={this} field="testovany" label="Testovaný" readOnly={true} labelStyle={{width:'7rem'}}/>
                                    </XFormInlineRow>
                                    <XInputDecimal form={this} field="id" label="Klient-služba ID" readOnly={true}/>
                                    <XInputDate form={this} field="modifDate" label="Dátum modif." readOnly={true}/>
                                    <XInputText form={this} field="modifXUser.name" label="Modifikoval" inputStyle={{width:'12.35rem'}}/>
                                </XFormCol>
                            </XFormRow>
                            <XFormRow>
                                <XFormCol width="full">
                                    <div className="field grid">
                                        <label htmlFor="sluzby" className="col-fixed" style={{width:'10rem'}}>Služby</label>
                                        <div>{this.createChipsSluzby()}</div>
                                    </div>
                                </XFormCol>
                            </XFormRow>
                            <XFormRow>
                                <XFormCol width="full">
                                    <XInputTextarea form={this} field="poznamka" label="Poznámka" labelOnTop={true} cols="full" autoResize={true}/>
                                </XFormCol>
                            </XFormRow>
                            <XFormRow>
                                <XFormCol width="full">
                                    <XInputTextarea form={this} field="poznamkaOsetrovna" label="Poznámka ošetrovňa" labelOnTop={true} cols="full" autoResize={true}/>
                                </XFormCol>
                            </XFormRow>
                            <XFormDataTable2 form={this} assocField="klientSluzbaZakazList" label="Prerušenia služby" addRowLabel="Pridať prerušenie">
                                <XFormColumn field="datumOd" header="Dátum od"/>
                                <XFormColumn field="datumDo" header="Dátum do"/>
                                <XFormTextareaColumn field="poznamka" header="Poznámka" width="54rem"/>
                            </XFormDataTable2>
                        </XFormCol>
                    </XFormRow>
                </XFormScrollable>
                <XFormFooter form={this}/>
            </div>
        );
    }

    renderAlzbetaOsetrovna() {
        return (
            <div>
                <XFormHeader form={this} label={this.createLabel()} appendNewRow={false}/>
                <XFormScrollable form={this} widthFitContent={true} headerFooterHeightInDialog='3.3rem'>
                    <XFormRow>{/* tato najvrchnejsia uroven XFormRow/XFormCol je tu na to aby ohranicila XInputTextarea "poznamka", inac by bol roztiahnuty na cely formular */}
                        <XFormCol>
                            <XFormRow>
                                <XFormCol labelStyle={{width:'10rem'}}>
                                    <XInputText form={this} field="klient.meno" label="Meno" inputStyle={{width:'15rem'}} readOnly={false} onChange={this.onChangeKlientMenoPriezviskoDatumNarodenia}/>
                                    <XInputText form={this} field="klient.priezvisko" label="Priezvisko" inputStyle={{width:'15rem'}} readOnly={false} onChange={this.onChangeKlientMenoPriezviskoDatumNarodenia}/>
                                    <XInputText form={this} field="klient.prezyvka" label="Prezývka" inputStyle={{width:'15rem'}} readOnly={false}/>
                                    <div className="x-form-inline-row">
                                        <XInputDate form={this} field="klient.datumNarodenia" label="Dátum narod." labelStyle={{width:'10rem'}} readOnly={false} onChange={this.onChangeKlientMenoPriezviskoDatumNarodenia}
                                                    scale={this.getKlientSluzba()?.klient?.datumNarodeniaIbaRok ? XDateScale.Year : XDateScale.Date}/>
                                        <XCheckbox form={this} field="klient.datumNarodeniaIbaRok" label="iba rok" labelStyle={{width:'5rem'}} readOnly={false} onChange={this.onChangeKlientDatumNarodeniaIbaRok}/>
                                    </div>
                                    <XDropdownEnum form={this} assocField="klient.pohlavie" label="Pohlavie" enumEnumCode={EnumEnum.pohlavie} readOnly={false}/>
                                </XFormCol>
                                <XFormCol>
                                    <KlientSluzbaAdresa form={this} osetrovna={true} labelStyle={{width:'11rem'}}/>
                                    <XInputDecimal form={this} field="id" label="Klient-služba ID" labelStyle={{width:'11rem'}} readOnly={true}/>
                                    <XInputDate form={this} field="modifDate" label="Dátum modif." labelStyle={{width:'11rem'}} readOnly={true}/>
                                    <XInputText form={this} field="modifXUser.name" label="Modifikoval" labelStyle={{width:'11rem'}} inputStyle={{width:'12.35rem'}}/>
                                </XFormCol>
                            </XFormRow>
                            <XFormRow>
                                <XFormCol width="full">
                                    <div className="field grid">
                                        <label htmlFor="sluzby" className="col-fixed" style={{width:'10rem'}}>Služby</label>
                                        <div>{this.createChipsSluzby()}</div>
                                    </div>
                                </XFormCol>
                            </XFormRow>
                            <XFormRow>
                                <XFormCol width="full">
                                    <XInputTextarea form={this} field="poznamkaOsetrovna" label="Poznámka ošetrovňa" labelOnTop={true} cols="full" autoResize={true}/>
                                </XFormCol>
                            </XFormRow>
                            <XFormDataTable2 form={this} assocField="klientSluzbaZakazList" label="Prerušenia služby" addRowLabel="Pridať prerušenie">
                                <XFormColumn field="datumOd" header="Dátum od"/>
                                <XFormColumn field="datumDo" header="Dátum do"/>
                                <XFormTextareaColumn field="poznamka" header="Poznámka" width="45rem"/>
                            </XFormDataTable2>
                        </XFormCol>
                    </XFormRow>
                </XFormScrollable>
                <XFormFooter form={this}/>
            </div>
        );
    }

    // poznamka: pt={{title: {style: {width: '100%'}}}} v elemente Panel nastavuje width na title - span zobrazujuci header text - nepotrebujeme to mat nastavene
    // asi to sluzi len na to ze ked sa tam da autocomplete, tak bude roztiahnuty na celu sirku
    renderSocPorad(sluzba: Sluzba) {
        const klientSluzba: KlientSluzba | null = this.getKlientSluzba();
        const headerFooterHeight: string = '16.5rem';
        const stlpec2LabelWidth: string = '10.5rem';
        return (
            <div>
                <XFormHeader form={this} label={this.createLabel()} appendNewRow={false}/>
                <XFormWidth form={this} width="102rem">
                    <TabView>
                        <TabPanel key="zdielane-udaje" header="Službami zdieľané údaje">
                            <XFormScrollable form={this} headerFooterHeight={headerFooterHeight} fixedHeight={true}>
                                <XFormRow>
                                    <XFormCol labelStyle={{width:'7.5rem'}}>
                                        <XInputText form={this} field="klient.meno" label="Meno" inputStyle={{width:'15rem'}} readOnly={false} onChange={this.onChangeKlientMenoPriezviskoDatumNarodenia}/>
                                        <XInputText form={this} field="klient.priezvisko" label="Priezvisko" inputStyle={{width:'15rem'}} readOnly={false} onChange={this.onChangeKlientMenoPriezviskoDatumNarodenia}/>
                                        <XInputText form={this} field="klient.prezyvka" label="Prezývka" inputStyle={{width:'15rem'}} readOnly={false}/>
                                        <XInputDecimal form={this} field="klient.id" label="Klient ID" readOnly={true}/>
                                        <XInputDate form={this} field="klient.modifDate" label="Dátum modif." readOnly={true}/>
                                        <XInputText form={this} field="klient.modifXUser.name" label="Modifikoval" inputStyle={{width:'12.35rem'}} readOnly={true}/>
                                    </XFormCol>
                                    <XFormCol>
                                        <div className="x-form-inline-row">
                                            <XInputText form={this} field="klient.rodneCislo" label="Rodné číslo" labelStyle={{width:stlpec2LabelWidth}} inputStyle={{width:'7.5rem'}} readOnly={false} placeholder="000000/0000"/>
                                            <XCheckbox form={this} field="klient.rodneCisloOverenyUdaj" label="overené" labelStyle={{width:'5rem'}} readOnly={false}/>
                                        </div>
                                        <div className="x-form-inline-row">
                                            <XInputDate form={this} field="klient.datumNarodenia" label="Dátum narodenia" labelStyle={{width:stlpec2LabelWidth}} readOnly={false} onChange={this.onChangeKlientMenoPriezviskoDatumNarodenia}
                                                        scale={this.getKlientSluzba()?.klient?.datumNarodeniaIbaRok ? XDateScale.Year : XDateScale.Date}/>
                                            <XCheckbox form={this} field="klient.datumNarodeniaIbaRok" label="iba rok" labelStyle={{width:'4.5rem'}} readOnly={false} onChange={this.onChangeKlientDatumNarodeniaIbaRok}/>
                                            <XCheckbox form={this} field="klient.datumNarodeniaOverenyUdaj" label="overený" labelStyle={{width:'5rem'}} readOnly={false}/>
                                        </div>
                                        <XDropdownEnum form={this} assocField="klient.pohlavie" label="Pohlavie" labelStyle={{width:'10.5rem'}} enumEnumCode={EnumEnum.pohlavie} readOnly={false}/>
                                        <div className="x-form-inline-row">
                                            <XInputText form={this} field="klient.obcianskyPreukaz" label="Občiansky preukaz" labelStyle={{width:stlpec2LabelWidth}} inputStyle={{width:'7.5rem'}} readOnly={false}/>
                                            <XInputDate form={this} field="klient.obcianskyPreukazDatumPlatnosti" label="dátum platnosti" labelStyle={{width:'8rem'}} readOnly={false}/>
                                        </div>
                                        <div className="x-form-inline-row">
                                            <XDropdownEnum form={this} assocField="klient.zdravotnaPoistovna" label="Zdravotná poisťovňa" labelStyle={{width:stlpec2LabelWidth}} enumEnumCode={EnumEnum.zdravotnaPoistovna} readOnly={false}/>
                                            {/* informácie o zmenách poisťovní a rok zmeny - nezdielany atribut! */}
                                            <XInputText form={this} field="zdravotnaPoistovnaZmeny" inputStyle={{width:'21rem'}}
                                                        desc="informácie o zmenách poisťovní a rok zmeny (nezdieľaný atribút!)"/>
                                        </div>
                                        <div className="field grid">
                                            <label htmlFor="sluzby" className="col-fixed" style={{width:stlpec2LabelWidth}}>Služby</label>
                                            <div style={{width:'27rem'}}>{this.createChipsSluzby()}</div>{/* 27 rem, aby sa wrapovalo, ked je vela chip-ov. 27 rem = sirka dropdown zdrav poist + zdravotnaPoistovnaZmeny */}
                                        </div>
                                    </XFormCol>
                                    <XFormCol>
                                        <KlientSluzbaAdresa form={this} osetrovna={false} labelStyle={{width:'7rem'}}/>
                                    </XFormCol>
                                </XFormRow>
                                <XFormDivider label="Nezdieľané údaje"/>
                                <XFormRow>
                                    <XFormCol width="full">
                                        <div className="x-form-inline-row">
                                            <XInputDecimal form={this} field="id" label="Klient-služba ID" readOnly={true}/>
                                            <XInputDate form={this} field="modifDate" label="Dátum modif." labelStyle={{width:'7.5rem'}} readOnly={true}/>
                                            <XInputText form={this} field="modifXUser.name" label="Modifikoval" labelStyle={{width:'7.5rem'}} inputStyle={{width:'12.35rem'}} readOnly={true}/>
                                        </div>
                                        {/* povodna realizacia "Miesta zdržiavania sa" cez link tabulku
                                        <div className="x-form-row justify-content-between align-items-center">
                                            <XAutoComplete form={this} assocField="klucovyPracovnikXUser" displayField="name" label="Kľúčový pracovník" suggestions={this.userSocialnyPracovnikList} width="15rem"/>
                                            {Utils.isSluzbaStreetwork() || Utils.isSluzbaOsetrovna() ?
                                                <XFormDataTable2 form={this} assocField="miestoMoznehoKontaktuKlientSluzbaList" label="Miesta zdržiavania sa">
                                                    <XFormColumn field="id" header="ID" readOnly={true} width="4rem"/>
                                                    <XFormAutoCompleteColumn assocField="miestoMoznehoKontaktu" displayField="miesto" header="Miesto" width="40rem"
                                                                             filter={Utils.filterCurrentSluzba()}
                                                                             searchBrowse={<MiestoMoznehoKontaktuBrowse/>} assocForm={<MiestoMoznehoKontaktuForm/>}/>
                                                </XFormDataTable2>
                                                : null
                                            }
                                        </div>
                                        */}
                                        {sluzba.kod === SluzbaEnum.streetwork || sluzba.kod === SluzbaEnum.alzbetaPoradenstvo ?
                                            <div className="x-form-inline-row">
                                                <XInputTextarea form={this} field="miestoZdrziavania" label="Miesto zdržiavania" cols="full" rows={1} autoResize={true}
                                                                fieldStyle={{width: `calc(100% - 8rem - 12rem)`}}
                                                                desc="opis aktuálneho miesta alebo posledného miesta, uviesť mesiac a rok"/>
                                                <XAutoComplete form={this} assocField="miestoZdrziavaniaMestskaCast" displayField="name"
                                                               label={"Mestská časť" + (this.mestskaCastPovinna(sluzba) ? " *" : "")}
                                                               filter={Utils.enumFilter(EnumEnum.mestskaCast)}
                                                               labelStyle={{width:'8rem'}} width="12rem"/>
                                            </div>
                                            : <div/>
                                        }
                                        <XInputTextarea form={this} field="kontakt" label="Kontakt" cols="full" rows={1} autoResize={true} desc="telefón, mail"/>
                                        <XAutoComplete form={this} assocField="klucovyPracovnikXUser" displayField="name" label="Kľúčový pracovník" suggestions={this.userSocialnyPracovnikList} width="15rem"/>
                                        <XInputTextarea form={this} field="poznamka" label="Poznámka z importu" cols="full" rows={1} autoResize={true} readOnly={true}/>
                                    </XFormCol>
                                </XFormRow>
                            </XFormScrollable>
                        </TabPanel>
                        <TabPanel key="mapovanie" header="Mapovanie">
                            <XFormScrollable form={this} headerFooterHeight={headerFooterHeight} fixedHeight={true}>
                                <XFormRow>
                                    <XFormCol width="full">
                                        <div className="flex align-items-center">
                                            <div id="mapaSpravaniaKlientaElemId" style={{width:'4rem'}}>Mapa správania klienta</div>
                                            <Tooltip target="#mapaSpravaniaKlientaElemId" content="Sociálny pracovník venuje pozornosť:
·  sprievodným javom: ako je zrozumiteľnosť podávania informácií, jasnosť kladených otázok, schopnosť formulovať otázky, prejavy úzkosti – potenie, chvenie, neschopnosť dodržať očný kontakt nesúvislé akoby nedopovedané vety, zrýchlené tempo reči,  príliš hlasitý, alebo nedostatočne počuteľný prejav
·  motorickým prejavom klienta: nepokoj, instabilita, gestikulácia, kývanie sa, pohyb rôznych častí tela a pod. môžu byť dôležitým zdrojom informácii o klientovi
·  emočný stav klienta: sa dá poznať na základe prejavov temperamentu (striedanie nálad, netrpezlivosť, výbušnosť, plačlivosť), rečového rytmu, poruchy dychu a pod."/>
                                            <div className="x-form-col" style={{width:'100%'}}>
                                                <XInputTextarea form={this} field="mapaSpravaniaFyziologicka" label="fyziologická" cols="full" autoResize={true} labelStyle={{width:'6rem'}}
                                                                desc="vonkajšie prejavy, opis postavy, chôdze, pozorované zdravotné ťažkosti, výrazné zdravotné obmedzenia, miera sebaobsluby"/>
                                                <XInputTextarea form={this} field="mapaSpravaniaMotoricka" label="motorická" cols="full" autoResize={true} labelStyle={{width:'6rem'}}
                                                                desc="Vonkajšie prejavy správania, Reč tela, pohyby pri komunikácii, gestikulácia, príznaky neurotického správania, či rozprávala nahlas, intonačné prejavy"/>
                                                <XInputTextarea form={this} field="mapaSpravaniaKognitivna" label="kognitívna afektívna" cols="full" autoResize={true} labelStyle={{width:'6rem'}}
                                                                desc="opis nálad, čo ich ovplyvňuje, závislosti/problematické užívanie návykových látok v súvislosti ovplvnenia nálad, ako pôsobí prejavovo (sebavedomie), či si uvedomuje svoju situáciu alebo riziká, ako ich vníma"/>
                                            </div>
                                        </div>
                                        <div className="flex align-items-center">
                                            <div id="rizikaVociSluzbeElemId" style={{width:'6rem'}}>Riziká voči službe a pracovníkom</div>
                                            <div className="x-form-col" style={{width:'100%'}}>
                                                <XInputTextarea form={this} field="rizikaVociSluzbeSpustacKUtokuUniku" label="spúšťač k útoku/úniku" cols="full" autoResize={true} labelStyle={{width:'11rem'}}
                                                                desc="Na čo si dávať pozor v komunikácii voči klientovi, alebo od klienta voči pracovníkovi. Jasné a stručné.
napr. téma, ktorú s klientom nemáme otvárať, reagoval na niečo agresívne, alebo sa uzavrel a nechcel komunikovať?"/>
                                                {!sluzba.utulok ? <XInputTextarea form={this} field="rizikaVociSluzbeVMiesteByvania" label="v mieste bývania/zdržiavania sa" cols="full" autoResize={true} labelStyle={{width:'11rem'}}
                                                                                           desc="Žije v oblasti diviakov? Nebezpečný pes? V okolí sú klienti (susedia) s agresívnym správaním?"/> : <div/>
                                                }
                                                <XInputTextarea form={this} field="rizikaVociSluzbeZoSpravaniaKlienta" label="zo správania klienta" cols="full" autoResize={true} labelStyle={{width:'11rem'}}
                                                                desc="Je agresívny pod vplyvom alkoholu/drog?"/>
                                            </div>
                                        </div>
                                        <XInputTextarea form={this} field="ineInformacie" label="Iné informácie" cols="full" autoResize={true}
                                                        desc="Využíva iné služby? Spolupracuje s inou službou, ak áno kontaktná osoba? rodné meno za slobodna,..."/>
                                    </XFormCol>
                                </XFormRow>
                            </XFormScrollable>
                        </TabPanel>
                        <TabPanel key="socialna-anamneza" header="Sociálna anamnéza">
                            <XFormScrollable form={this} headerFooterHeight={headerFooterHeight} fixedHeight={true}>
                                <XFormRow>
                                    <XFormCol width="full">
                                        <div className="x-form-inline-row">
                                            <XMultiSelect form={this} assocToMany="socPostavenieList" displayField="name"
                                                          label="Sociálne postavenie" filter={Utils.enumFilter(EnumEnum.socialnePostavenie)} sortField="enumOrder"/>
                                            <XDropdownEnum form={this} assocField="rodinnyStav" label="Rodinný stav" labelStyle={{width:'7rem'}} enumEnumCode={EnumEnum.rodinnyStav}/>
                                        </div>
                                        {Utils.getCurrentSluzba()?.kod === SluzbaEnum.komunitneCentrumZahorskaVes ?
                                            <XMultiSelect form={this} assocToMany="uzivatelSluzbyList" displayField="name"
                                                          label="Užívateľ služby" filter={Utils.enumFilter(EnumEnum.uzivatelSluzby)} sortField="enumOrder"/>
                                            : <div/>
                                        }
                                        <XMultiSelect form={this} assocToMany="dokladList" displayField="name"
                                                      label="Doklady" filter={Utils.enumFilter(EnumEnum.doklady)} sortField="enumOrder"/>
                                        <XFormDataTable2 form={this} assocField="klientSluzbaPrijemList" label="Pravidelný príjem">
                                            <XFormAutoCompleteColumn assocField="druhPrijmu" displayField="name"
                                                                 header="Druh príjmu"
                                                                 filter={Utils.enumFilter(EnumEnum.druhPrijmu)}
                                                                 sortField="enumOrder" width="19rem"/>
                                            <XFormColumn field="mesiacOd" header="Mesiac od" width="7.5rem"/>
                                            <XFormColumn field="mesiacDo" header="Mesiac do" width="7.5rem"/>
                                            <XFormColumn field="vyskaPrijmu" header="Výška príjmu" width="8rem"/>
                                            <XFormColumn field="datumASposobVyplatyPrijmu"
                                                         header="Dátum a spôsob výplaty príjmu" width="20rem"/>
                                        </XFormDataTable2>
                                        <XInputTextarea form={this} field="majetok" label="Majetok" cols="full" autoResize={true} labelStyle={{width:'7rem'}}/>
                                        <XInputTextarea form={this} field="dlhyExekucie" label="Dlhy/exekúcie" cols="full" autoResize={true} labelStyle={{width:'7rem'}}/>
                                        <div className="x-form-inline-row">
                                            <XAutoComplete form={this} assocField="vzdelanie" displayField="name" label="Vzdelanie" labelStyle={{width:'7rem'}} width="15rem" filter={Utils.enumFilter(EnumEnum.vzdelanie)}/>
                                            <XInputTextarea form={this} field="vzdelaniePopis" label="Popis" labelStyle={{width:'4rem'}} cols="full" rows={1} autoResize={true}
                                                            fieldStyle={{width: `calc(100% - 7rem - 15rem)`}}
                                                            desc="názov školy, roky, učebný odbor"/>
                                        </div>
                                        <XInputTextarea form={this} field="praca" label="Práca" cols="full" autoResize={true} labelStyle={{width:'7rem'}}
                                                        desc="Priebeh zamestnaní a roky, koľko má odpracovaných rokov, aké pracovné skúsenosti získal, má kurzy, iné doplňujúce vzdelanie?"/>
                                        <XInputTextarea form={this} field="rodina" label="Rodina" cols="full" autoResize={true} labelStyle={{width:'7rem'}}
                                                        desc="Je s rodinou v kontakte? Či nad tým niekedy premýšľa, aké mali vzťahy? Má deti, súrodencov, rodičov?"/>
                                        <XInputTextarea form={this} field="nudzovyKontakt" label="Núdzový kontakt" cols="full" rows={1} autoResize={true}
                                                        desc="Koho môžeme kontaktovať v prípade zhoršenia zdravotného stavu? Telefonický kontakt s menom a vzťahom voči klientovi."/>
                                        <XInputTextarea form={this} field="anamneza" label="Anamnéza" cols="full" autoResize={true}
                                                        desc="...informácie o živote klienta, detstvo, zážitky, úspechy, neúspechy
...hypotéza o probléme, odkiaľ prišiel-na podnet koho"/>
                                        <XInputTextarea form={this} field="osobneZaujmy" label="Osobné záujmy, záľuby, trávenie voľného času" cols="full" autoResize={true}/>
                                        <XInputTextarea form={this} field="socialnyProblem" label="Sociálny problém" cols="full" autoResize={true}
                                                        desc="...hypotéza o probléme, príčina vzniku, charakteru a rozsahu problému
...pomenovanie problému alebo nepriaznivej sociálnej situácie klientom
...analýza problému a momentálny stav, zhodnotenie sociálnym pracovníkom z pozorovania, rozhovorov, počúvania, analýza dokumentov"/>
                                        <XInputTextarea form={this} field="kedyStratilByvanie" label="Kedy prvý krát stratil bývanie?" cols="full" autoResize={true}/>
                                        <XInputTextarea form={this} field="akoPrisielOByvanie" label="Ako/prečo prišiel o stabilné bývanie?" cols="full" autoResize={true}/>
                                        <XInputTextarea form={this} field="akoRiesilSituaciu" label="Ako riešil svoju situáciu?" cols="full" autoResize={true}/>
                                    </XFormCol>
                                </XFormRow>
                            </XFormScrollable>
                        </TabPanel>
                        <TabPanel key="zdravotna-anamneza" header="Zdravotná anamnéza">
                            <XFormScrollable form={this} headerFooterHeight={headerFooterHeight} fixedHeight={true}>
                                <XFormRow>
                                    <XFormCol width="full">
                                        <XInputTextarea form={this} field="zdravotnaAnamneza" label="Zdravotná anamnéza" cols="full" autoResize={true}
                                                        desc="Informácie, ktoré udáva klient- ochorenia, zdravotné ťažkosti aktuálne, úrazy, prekonané ochorenia v minulosti."/>
                                        <XInputTextarea form={this} field="alergie" label="Alergie" cols="full" rows={1} autoResize={true}/>
                                        <div className="field grid">
                                            <label htmlFor="diagnozy" className="col-fixed" style={{width: `${XUtils.FIELD_LABEL_WIDTH}`}}>Diagnózy</label>
                                            {/* diagnozy su zapisane do atributu typu json vo formate: [<kod1>, <kod2>, ..., <kodN>] */}
                                            <MultiSelect value={this.state.object?.diagnozy ?? []} onChange={(e) => {this.state.object.diagnozy = e.value; this.setStateXForm();}}
                                                         options={diagnozyOptions} optionGroupChildren="items" optionGroupLabel="label" optionLabel="label" optionValue="code"
                                                         display="chip"/>
                                        </div>
                                        <div className="x-form-inline-row">
                                            <XInputTextarea form={this} field="lieky" label="Lieky" cols="full" rows={1} autoResize={true}
                                                            fieldStyle={{width: `calc(100% - 9.5rem - 8.4rem)`}}/>
                                            <XInputDate form={this} field="liekyDatumAktualizacie" label="dátum aktualizácie" labelStyle={{width:'9.5rem'}}/>
                                        </div>
                                        <XInputTextarea form={this} field="vseobecnyLekar" label="Všeobecný lekár" cols="full" rows={1} autoResize={true}/>
                                        <XInputTextarea form={this} field="odbornyLekar" label="Odborný lekár" cols="full" autoResize={true}/>
                                        <XFormDataTable2 form={this} assocField="klientSluzbaOdkazanostList" label="Odkázanosť na sociálnu službu">
                                            <XFormAutoCompleteColumn assocField="odkazanost" displayField="name"
                                                                     header="Odkázanosť"
                                                                     filter={Utils.enumFilter(EnumEnum.odkazanostNaSocialnuSluzbu)}
                                                                     sortField="enumOrder" width="25rem"/>
                                            <XFormAutoCompleteColumn assocField="stupenOdkazanosti" displayField="name"
                                                                     header="Stupeň odkázanosti"
                                                                     filter={Utils.enumFilter(EnumEnum.stupenOdkazanosti)}
                                                                     sortField="enumOrder" width="10rem"/>
                                        </XFormDataTable2>
                                    </XFormCol>
                                </XFormRow>
                            </XFormScrollable>
                        </TabPanel>
                        <TabPanel key="ukoncenie-katamneza" header="Ukončenie/katamnéza">
                            <XFormScrollable form={this} headerFooterHeight={headerFooterHeight} fixedHeight={true}>
                                <XFormRow>
                                    <XFormCol width="full">
                                        <XFormDataTable2 form={this} assocField="klientSluzbaUkonceniePoskytSocPoradList" label="Ukončenie poskytovania sociálneho poradenstva"
                                                         onClickAddRow={this.onClickAddRowUkoncenie}>
                                            <XFormColumn field="datum" header="Dátum" readOnly={true}/>
                                            <XFormTextareaColumn field="dovod" header="Dôvod" width="40rem"/>
                                            <XFormColumn field="datumPoslKontaktu" header="Dátum posl. kontaku"/>
                                            <XFormAutoCompleteColumn assocField="poslKlucovyPracovnikXUser" displayField="name" header="Posledný kľúčový pracovník"
                                                                     suggestions={this.userSocialnyPracovnikList} width="15rem"/>
                                        </XFormDataTable2>
                                        {klientSluzba ? <KlientSluzbaKatamneza klientSluzbaId={klientSluzba.id}/> : <div/>}
                                    </XFormCol>
                                </XFormRow>
                            </XFormScrollable>
                        </TabPanel>
                        <TabPanel key="individualny-plan" header="Individuálny plán">
                            <XFormScrollable form={this} headerFooterHeight={headerFooterHeight} fixedHeight={true}>
                                <XFormRow>
                                    <XFormCol width="full">
                                        <div className="x-form-inline-row">
                                            <XInputDate form={this} field="individPlanDatumSpracovania" label="Dátum spracovania"/>
                                            <XInputDate form={this} field="individPlanDatumAktualizacie" label="Dátum aktualizácie" labelStyle={{width:'10rem'}}/>
                                        </div>
                                        <XInputTextarea form={this} field="temyNaRozhovor" label="Témy na rozhovor" cols="full" autoResize={true}
                                                        desc="Ponuka sociálneho poradenstva, témy, postrehy, ktoré chceme otvárať na rozhovore s klientom. Témy vyplývajúce so sociálnej diagnostiky a porád.
Potencionálne možnosti klienta pre zlepšenie, stabilizáciu sociálnej situácie a problému. (napr. posilnenie sociálnych zručností, adaptácia, integrácia, kompenzácia nepriaznivého zdravotného stavu).
Otázky, ktoré sa klienta treba spýtať pri stretnutí."/>
                                        <XInputTextarea form={this} field="potrebyOcakavaniaKlienta" label="Potreby očakávania klienta" cols="full" autoResize={true}
                                                        desc="Identifikované individuálne potreby, priania, očakávania klienta kam sa chce dostať.
Potreby, očávania a sny klienta, zlepšenie situácie, v ktorej sa nachádza ako vie cieľ dosianuť.
Môže ísť aj o pomenovanie nepriaznivej sociálnej situácie, ktorú chce zmeniť."/>
                                        <XFormDivider label="Ciele spolupráce"/>
                                        <XFormPanelList form={this} assocField="klientSluzbaCielSpolupraceList" panelBody={this.panelBodyKlientSluzbaCielSpoluprace} label="" addRowLabel="Pridať cieľ"/>
                                        <XFormDivider label="Metódy spolupráce"/>
                                        <div className="flex align-items-center">
                                            <div style={{width:'7rem'}}>Metódy spolupráce, ktoré sme pri intervencii aplikovali</div>
                                            <div className="x-form-col" style={{width:'100%'}}>
                                                <div className="field grid">
                                                    <div id="individ-plan-metody-zo-zapisov" className="flex align-items-center" style={{width:'6rem'}}>metódy zo zápisov</div>
                                                    <Tooltip target="#individ-plan-metody-zo-zapisov" content="Metódy spolupráce zakliknuté na zápisoch"/>
                                                    <div style={{width:'calc(100% - 6rem)'}}>{klientSluzba ? <KlientSluzbaZakliknuteFieldy klientSluzbaId={klientSluzba.id} fieldGroup="metodyprace"/> : <div/>}</div>
                                                </div>
                                                <XInputTextarea form={this} field="metodySpolupraceVlastnyPopis" label="vlastný popis" labelStyle={{width:'6rem'}} cols="full" autoResize={true}
                                                                desc="Vlastný popis zvolených metód"/>
                                            </div>
                                        </div>
                                        <div className="field grid">
                                            <div id="individ-plan-hodnotenia" className="flex align-items-center" style={{width:'6rem'}}>Hodnotenia</div>
                                            <Tooltip target="#individ-plan-hodnotenia" content='Dátumy zápisov na ktorých je zakliknuté "hodnotenie"'/>
                                            <div style={{width:'calc(100% - 6rem)'}}>{klientSluzba ? <KlientSluzbaHodnoteniaDatumy klientSluzbaId={klientSluzba.id}/> : <div/>}</div>
                                        </div>
                                    </XFormCol>
                                </XFormRow>
                            </XFormScrollable>
                        </TabPanel>
                        <TabPanel key="rizikovy-plan" header="Rizikový plán">
                            <XFormScrollable form={this} headerFooterHeight={headerFooterHeight} fixedHeight={true}>
                                <XFormRow>
                                    <div className='x-form-col' style={{width: '100%'}}>
                                        <div className="x-form-inline-row">
                                            <XInputDate form={this} field="rizikovyPlanDatumSpracovania" label="Dátum spracovania"/>
                                            <XInputDate form={this} field="rizikovyPlanDatumAktualizacie" label="Dátum aktualizácie" labelStyle={{width:'10rem'}}/>
                                        </div>
                                        <XInputTextarea form={this} field="rizika" label="Riziká" cols="full" autoResize={true}
                                                        desc="Aké riziká alebo krízová situácia môže nastať? Rozhovory o následkoch, prevencii.
Napr. riziko pádu, riziko preležanín, napadnutie partnerom, vyhorená chatka, prespávanie v kontajneri - ohrozenie života, strata financii, okradnutie alebo iné..."/>
                                        <div className="field grid">
                                            <label className="col-fixed" style={{width:'28rem'}}>Rozumová zložka - mohol posúdiť následky svojho konania?</label>
                                            <XBooleanYesNoBase value={klientSluzba?.rizikaRozumovaZlozka ?? null} onChange={(value: boolean | null) => {klientSluzba!.rizikaRozumovaZlozka = value; this.setStateXForm();}}/>
                                        </div>
                                        <div className="field grid">
                                            <label className="col-fixed" style={{width:'28rem'}}>Vôľová zložka - mohol prijímateľ svoje konanie ovládnuť?</label>
                                            <XBooleanYesNoBase value={klientSluzba?.rizikaVolovaZlozka ?? null} onChange={(value: boolean | null) => {klientSluzba!.rizikaVolovaZlozka = value; this.setStateXForm();}}/>
                                        </div>
                                        <XInputTextarea form={this} field="faktoryVznikuAPrevencia" label="Faktory vzniku a prevencia" cols="full" autoResize={true}
                                                        desc="Čo ovplyvňuje vznik rizikovej situácie? Aké sú spúšťače a faktory, ktoré môže vnímať klient ako nápomocné v predchádzaní krízy. (napr. keď je partner pod vplyvom alkoholu)
Došlo už k podobnej situácii? (napr. ak vznikajú hádky po pití alkoholu určiť si mieru spoločného pitia, neriešiť veci pod vplyvom alkoholu..)"/>
                                        <XInputTextarea form={this} field="postupKrizovejIntervencie" label="Postup krízovej intervencie a jej vyhodnotenie" cols="full" autoResize={true}
                                                        desc="Čo budeme robiť, ak nastane? Ako minimalizujeme riziko? Nadstavenie deeskalčných techník na zvládanie krízy, ktoré sú konzultované s klientom.
Vyhodnotenie zvoleného prístupu a jeho efekt pre zúčastnené strany, účinok ak došlo k nežiadúcej udalosti"/>
                                        {sluzba.utulok ?
                                            [<XMultiSelect form={this} assocToMany="podielajucaSaOsobaList" displayField="name"
                                                           label="Podieľajúce sa osoby na rizikovom pláne" filter={Utils.enumFilter(EnumEnum.podielajuceSaOsoby)} sortField="enumOrder"/>,
                                            <XInputTextarea form={this} field="podielajuceSaOsobyDalsi" label="ďalšie osoby" cols="full" rows={1} autoResize={true}/>]
                                            : <div/>
                                        }
                                    </div>
                                </XFormRow>
                            </XFormScrollable>
                        </TabPanel>
                        <TabPanel key="socialna-rehabilitacia" header="Sociálna rehabilitácia">
                            <XFormScrollable form={this} headerFooterHeight={headerFooterHeight} fixedHeight={true}>
                                <XFormRow>
                                    <XFormCol width="full">
                                        <XFormPanelList form={this} assocField="klientSluzbaSocRehabList" panelBody={this.panelBodyKlientSluzbaSocRehab} label="" addRowLabel="Pridať záznam"/>
                                    </XFormCol>
                                </XFormRow>
                            </XFormScrollable>
                        </TabPanel>
                        <TabPanel key="samostatnost" header="Samostatnosť">
                            <XFormScrollable form={this} headerFooterHeight={headerFooterHeight} fixedHeight={true}>
                                <XFormRow>
                                    <XFormCol width="full">
                                        <KlientSluzbaSamostCinnost form={this} label="Jedenie, pitie" field="samostJedeniePitie"
                                                                   popis2="2 - nezávislý, prijímateľ môže jesť sám jedlo z podnosu alebo zo stola, keď má jedlo na dosah alebo mu ho niekto prinesie; musí byť schopný položiť jedlo na príručný stolík, ak je to potrebné; krája si jedlo, používa soľ a korenie, natiera si maslo a pod; aktivitu musí uskutočniť v primeranom čase"
                                                                   popis1="1 - potrebuje nejakú pomoc (napr. s krájaním jedla)"/>
                                        <KlientSluzbaSamostCinnost form={this} label="Obliekanie, vyzliekanie" field="samostObliekanieVyzliekanie"
                                                                   popis2="2 - prijímateľ je schopný si obliecť, zapnúť a vyzliecť všetko oblečenie, zašnurovať si šnurovacie topánky (pokiaľ k tomu nepotrebuje špeciálne pomôcky); tieto aktivity zahŕňajú aj obutie a vyzutie a manipuláciu s nejakou ortopedickou pomôckou, ak je predpísaná (napr. ortéza)"
                                                                   popis1="1 - potrebuje nejakú pomoc"/>
                                        <KlientSluzbaSamostCinnost form={this} label="Kúpanie, osobná hygiena" field="samostKupanieOsobnaHygiena"
                                                                   popis2="2 - prijímateľ môže používať vaňu, sprchu alebo sa kompletne umyje špongiou pri umývadle, musí byť schopný urobiť všetky kroky spojené s hociktorou metódou, je schopný umyť si ruky a tvár, učesať vlasy, vyčistiť zuby a oholiť sa (môže použiť rôzny spôsob holenia, ale musí manipulovať so žiletkou alebo zapojiť holiaci strojček bez pomoci a tiež vybrať ho zo zásuvky alebo zo skrinky) ale bez prítomnosti inej osoby"
                                                                   popis1="1 – potrebuje pomoc"/>
                                        <KlientSluzbaSamostCinnost form={this} label="Použitie WC" field="samostPouzitieWC"
                                                                   popis2="2 -prijímateľ je schopný dostať sa na a z toaletnej misy, zapnúť a rozopnúť oblečenie, vyzliecť a obliecť, zabrániť ušpineniu oblečenia a použiť toaletný papier bez pomoci; ak potrebuje, môže použiť nástennú tyč/madlo alebo iný stabilný predmet na podporu/pridržanie sa; ak je potrebné použiť podložnú misu namiesto záchoda, musí byť schopný posadiť sa na kreslo, vyprázdniť, utrieť a umyť"
                                                                   popis1="1 - potrebuje pomoc s udržaním stability alebo pri manipulácii s oblečením alebo použitím toaletného papiera"/>
                                        <KlientSluzbaSamostCinnost form={this} label="Presun zo stoličky do postele" field="samostPresunZoStolickyDoPostele"
                                                                   popis2="2 - nezávislý vo všetkých fázach týchto činností; pacient sa môže bezpečne priblížiť k posteli na vozíku, zabrzdiť ho, zdvihnúť opierku nôh, bezpečne sa dostať do postele, ľahnúť si, posadiť sa na okraj postele, zmeniť polohu, ak je to potrebné, presunie sa bezpečne naspäť na vozík"
                                                                   popis1="1 – pacient vyžaduje pomoc"/>
                                        <KlientSluzbaSamostCinnost form={this} label="Chôdza po rovine" field="samostChodzaPoRovine"
                                                                   popis2="2 - môže sa prechádzať najmenej 50 m bez pomoci alebo dozoru, môže používať statické pomôcky (napr. podpera, protéza, palica, chodítko), ale nie s vlastným pohonom/kolieskami; pri používaní dlahy a korzetu musí byť schopný si ich upevniť a uvoľniť, nastaviť do požadovanej pozície, vstať a posadiť sa a odložiť pomôcky keď sedí"
                                                                   popis1="1 - potrebuje pomoc a dozor v činnosti uvedenej vyššie a môže ísť 50 m s malou pomocou. Alebo pacient nie je chodiaci, ale na vozíku je nezávislý – musí byť schopný manipulovať s vozíkom okolo rohov, otočiť sa, manévrovať okolo stola, postele, záchoda atď., musí byť schopný ísť na vozíku aspoň 50 m"/>
                                        <KlientSluzbaSamostCinnost form={this} label="Chôdza po schodoch" field="samostChodzaPoSchodoch"
                                                                   popis2="2 - prijímateľ je schopný ísť dole a hore po schodoch bezpečne bez pomoci alebo dozoru, môže použiť zábradlie, palicu alebo barle, ak potrebuje; musí byť schopný niesť/prenášať palicu alebo barle, ak ide hore alebo dole po schodoch"
                                                                   popis1="1 - potrebuje pomoc alebo dozor pri týchto činnostiach"/>
                                    </XFormCol>
                                </XFormRow>
                            </XFormScrollable>
                        </TabPanel>
                        <TabPanel key="prerusenia" header="Prerušenia služby">
                            <XFormScrollable form={this} headerFooterHeight={headerFooterHeight} fixedHeight={true}>
                                <XFormRow>
                                    <XFormCol width="full">
                                        <XFormDataTable2 form={this} assocField="klientSluzbaZakazList" label="" addRowLabel="Pridať prerušenie">
                                            <XFormColumn field="datumOd" header="Dátum od"/>
                                            <XFormColumn field="datumDo" header="Dátum do"/>
                                            <XFormTextareaColumn field="poznamka" header="Poznámka" width="80rem"/>
                                        </XFormDataTable2>
                                    </XFormCol>
                                </XFormRow>
                            </XFormScrollable>
                        </TabPanel>
                    </TabView>
                </XFormWidth>
                <XFormFooter form={this}/>
            </div>
        );
    }
}
