import {XBrowseProps, XLazyColumn, XLazyDataTable} from "@michalrakus/x-react-web-lib/XLazyDataTable";
import React from "react";
import {Utils} from "../../Utils";
import {ZapisForm} from "./ZapisForm";
import {SluzbaEnum} from "../../common/enums";
import {KlientSluzbaForm} from "../klient/KlientSluzbaForm";

export const ZapisBrowse = (props: XBrowseProps) => {

    const onAddRow = () => {

        if (!Utils.getCurrentSluzba()) {
            alert("Vyberte najprv službu.");
            return;
        }

        if (Utils.getCurrentSluzba()?.kod === SluzbaEnum.streetwork) {
            alert("Zápis pre streetwork je dovolené vytvoriť len cez voľbu Zápisy - streetwork.");
            return;
        }

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        props.openForm!(<ZapisForm/>);
    }

    const onEdit = (selectedRow: any) => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        props.openForm!(<ZapisForm id={selectedRow.id}/>);
    }

    const [fieldList, minLength]: [string[], number] = Utils.klientSluzbaFieldListForAutoComplete();

    return (
        <div>
            <XLazyDataTable entity="Zapis" label={Utils.isSluzbaNoclaharen() ? "Ošetrenia" : "Zápisy"} sortField={[{field: "datum", order: -1}, {field: "id", order: -1}]} rows={30}
                            customFilter={Utils.filterCurrentSluzba()}
                            onAddRow={onAddRow} onEdit={onEdit} removeRow={true} displayed={props.displayed} multilineSwitch={true}>
                <XLazyColumn field="id" header="ID" width="5rem"/>
                <XLazyColumn field="datum" header="Dátum" betweenFilter="column"/>
                <XLazyColumn field="klientSluzba.klient.menoPriezviskoPrezyvka" header="Klient" width="12rem"
                             autoComplete={{
                                 assocField: "klientSluzba", field: fieldList, filter: Utils.filterCurrentSluzba(), valueForm: <KlientSluzbaForm/>,
                                 lazyLoadMaxRows: 15, minLength: minLength, scrollHeight: "50rem"
                             }}/>
                <XLazyColumn field="vseobecnyZapis" header="Zápis" width="70rem" contentType="html"/>
                <XLazyColumn field="atributy" fieldSetId="zapis" header="Klikačky" width="20rem"/>
            </XLazyDataTable>
        </div>
    );
}
