import React, {useState} from "react";
import {
    XBrowseProps, XFilterElementParams,
    XLazyColumn,
    XLazyDataTable, XOptionalCustomFilter, XSearchBrowseProps
} from "@michalrakus/x-react-web-lib/XLazyDataTable";
import {KlientForm} from "./KlientForm";
import {ColumnBodyOptions} from "primereact/column";
import {AsUIType, convertValueBase, XDateScale} from "@michalrakus/x-react-web-lib/XUtilsConversions";
import {Klient} from "../../model/klient/klient.entity";
import {Utils} from "../../Utils";
import {RadioButton, RadioButtonChangeEvent} from "primereact/radiobutton";
import {MergeKlientRequest} from "../../common/klient-api";
import {XUtils} from "@michalrakus/x-react-web-lib/XUtils";

const whereMenoPriezvisko: string =
    ` AND ${Utils.stringFieldAsDBNormalised("k2.meno")} = ${Utils.stringFieldAsDBNormalised("[meno]")}`
    + ` AND ${Utils.stringFieldAsDBNormalised("k2.priezvisko")} = ${Utils.stringFieldAsDBNormalised("[priezvisko]")}`;

const optionalCustomFilters: XOptionalCustomFilter[] = [
    {
        label: "Duplicity - meno, priezvisko",
        filter: {where: `exists (select 1 from ${Utils.getSchema()}.klient k2 where`
                + ` k2.id <> [id]`
                + whereMenoPriezvisko
                + `)`
                , params: {}}
    },
    {
        label: "Duplicity - meno, priezvisko, dátum/rok narod.",
        filter: {where: `exists (select 1 from ${Utils.getSchema()}.klient k2 where`
                + ` k2.id <> [id]`
                + whereMenoPriezvisko
                + ` and (((k2.datum_narodenia_iba_rok or [datumNarodeniaIbaRok]) and date_trunc('year', k2.datum_narodenia)::DATE = date_trunc('year', [datumNarodenia])::DATE)`
                + `   	or ((not k2.datum_narodenia_iba_rok and not [datumNarodeniaIbaRok]) and k2.datum_narodenia = [datumNarodenia]))`
                + `)`
            , params: {}}
    },
    {
        label: "Duplicity - rodné číslo",
        filter: {where: `[rodneCislo] <> ''`
                + ` and exists (select 1 from ${Utils.getSchema()}.klient k2 where`
                + ` k2.id <> [id]`
                + ` and replace(k2.rodne_cislo, '/', '') = replace([rodneCislo], '/', '')`
                + `)`
            , params: {}}
    }
];

// pouziva sa v HladatKlientaMimoSluzbuDialog a tiez v menu Adminstracia na kontrolu/odstranovanie duplicit
// velmi podobne ako KlientSluzbaBrowse
export const KlientBrowse = (props: XBrowseProps & XSearchBrowseProps & {label?: string;}) => {

    const [mergeInProgress, setMergeInProgress] = useState<boolean>(false);
    const [selectedRowRadio, setSelectedRowRadio] = useState<Klient | undefined>(undefined); // used if mergeInProgress = true

    // boolean stav dataLoaded vytiahnuty z XLazyDataTable, aby sme mohli zavolat reload dat (skopirovane z skch-finance)
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    const onAddRow = () => {

        alert("Klientov je možné vytvárať len v rámci jednotlivých služieb (cez menu Aplikácia/Nocľaháreň).");
        return;
    }

    const onEdit = (selectedRow: any) => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        props.openForm!(<KlientForm id={selectedRow.id}/>);
    }

    const onMerge = async (selectedRow: Klient) => {
        if (!selectedRow) {
            alert("Vyselektujte záznam, ktorý chcete zlúčiť.");
            return;
        }

        if (!mergeInProgress) {
            // go to merge step 2 (show radio button column)
            setMergeInProgress(true);
        }
        else {
            if (!selectedRowRadio) {
                alert(`Kliknutím na krúžok označte záznam, do ktorého chcete pridať vyselektovaný záznam a ešte raz stlačte "Zlúčiť záznam - krok 2."`);
                return;
            }
            if (selectedRowRadio === selectedRow) {
                alert(`Musíte označiť iný záznam ako vyselektovaný."`);
                return;
            }
            await mergeRows(selectedRow, selectedRowRadio);
            // back to original state
            setMergeInProgress(false);
            setSelectedRowRadio(undefined);
        }
    }

    const mergeRows = async (rowToRemove: Klient, rowToStay: Klient) => {
        // TODO - upozornenie ak by malo dojst k vymazu zaznamu KlientSluzba (ta ista sluzba na "rowToRemove" aj na "rowToStay")
        // idealne vypisat zoznam sluzieb, pri ktorych dojde k vymazu
        const mergeKlientRequest: MergeKlientRequest = {klientToRemoveId: rowToRemove.id, klientToStayId: rowToStay.id};
        try {
            await XUtils.post('merge-klient', mergeKlientRequest);
            // refresh dat z DB
            setDataLoaded(false);
        }
        catch (e) {
            XUtils.showErrorMessage("Nepodarilo sa zlúčiť záznam.", e);
            // nechame zbehnut kod ktory vrati browse do povodneho stavu
        }
    }

    const radioBody = (klient: Klient, options: ColumnBodyOptions): React.ReactNode => {
        return <RadioButton value={klient} onChange={(e: RadioButtonChangeEvent) => setSelectedRowRadio(e.value)} checked={klient === selectedRowRadio} />;
    }

    const klientDatumNarodeniaBody = (klient: Klient, options: ColumnBodyOptions): React.ReactNode => {
        return convertValueBase('date', klient.datumNarodeniaIbaRok ? XDateScale.Year : XDateScale.Date, klient.datumNarodenia, true, AsUIType.Form);
    }

    return (
        <XLazyDataTable entity="Klient" label={props.label ?? "Klienti"} sortField="id desc" rows={30}
                        onAddRow={onAddRow} onEdit={onEdit} removeRow={true}
                        dataLoadedState={[dataLoaded, setDataLoaded]}
                        optionalCustomFilters={optionalCustomFilters}
                        appButtonsForRow={Utils.isUserAdmin() ? [{key: "button-merge", label: !mergeInProgress ? "Zlúčiť záznam" : "Zlúčiť záznam - krok 2", onClick: onMerge}] : undefined}
                        displayed={props.displayed} searchBrowseParams={props.searchBrowseParams}>
            <XLazyColumn field="id" header="Do" width="2rem" columnViewStatus={mergeInProgress}
                         body={radioBody} filterElement={(params: XFilterElementParams) => null} showFilterMenu={false}/>{/* nezobrazime filter (trosku hack) */}
            <XLazyColumn field="id" header="ID" width="5rem"/>
            <XLazyColumn field="menoPriezviskoPrezyvka" header="Meno priezvisko prezývka" width="20rem"/>
            <XLazyColumn field="datumNarodenia" header="Dátum narodenia" body={klientDatumNarodeniaBody}/>
            <XLazyColumn field="ulica" header="Ulica" width="13rem"/>
            <XLazyColumn field="cisloDomu" header="Číslo domu" width="7rem"/>
            <XLazyColumn field="psc" header="PSČ" width="5rem"/>
            <XLazyColumn field="obecText" header="Mesto/obec (text)" width="11rem"/>
            <XLazyColumn field="obec.nazov" header="Mesto/obec" width="10rem"/>
            <XLazyColumn field="obec.okres.nazov" header="Okres" width="10rem" autoComplete={true}/>
            <XLazyColumn field="stat.nazov" header="Štát" width="8rem" autoComplete={true}/>
        </XLazyDataTable>
    );
}
